import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import getContrastingColor from 'utils/getContrastingColor';

export const OuterContainer = styled.div<{ backgroundColor: string }>`
  ${({ backgroundColor }) =>
    backgroundColor
      ? `background-color: ${backgroundColor}; 
         h2, p, div, span { color: ${getContrastingColor(backgroundColor)}; };`
      : ''}
`;

export const InnerContainer = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: 0 auto;
  padding: ${SPACER.x4large} ${SPACER.large};
`;

export const Header = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.64px;
  margin-bottom: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    max-width: 500px;
  }

  ${MEDIA_QUERIES.xlUp} {
    font-size: 52px;
    max-width: 800px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: ${SPACER.x5large};
  }
`;

export const ValuePropListContainer = styled.div`
  margin: 0 0 ${SPACER.xlarge};

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
    max-width: 100%;
    gap: ${SPACER.medium};
    margin-bottom: 120px;
  }

  ${MEDIA_QUERIES.lgUp} {
    margin-bottom: ${SPACER.x5large};
  }

  ${MEDIA_QUERIES.xlUp} {
    gap: ${SPACER.large};
    margin-bottom: 120px;
  }

  ${MEDIA_QUERIES.xxlUp} {
    margin-bottom: ${SPACER.x5large};
  }
`;

export const ValuePropContainer = styled.div`
  display: flex;
  gap: ${SPACER.medium};
  padding: ${SPACER.small};

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: column;
    gap: 6px;
  }
`;

export const SVGContainer = styled.div`
  svg {
    align-self: center;
  }
`;

export const ValueProp = styled.div`
  font-size: ${SPACER.medium};
`;

export const desktopCtaStyles = css`
  ${desktopOnly()}
  font-size: 16px;
  font-weight: 700;
  line-height: 132%;
  letter-spacing: -0.32px;
  max-width: 258px;
  margin-left: auto;
`;

export const mobileCtaStyles = css`
  ${mobileOnly()}
`;
