import React from 'react';
import { type BenefitsBarProps } from '..';

export const RETAIL_OFFER_RESKIN_BENEFITS_BAR_CONTENT: BenefitsBarProps = {
  title: (
    <React.Fragment>
      Get even more from Curology with the Custom Formula<sup>Rx</sup>
    </React.Fragment>
  ),
  valueProps: [
    'Up to 2x more effective than over-the-counter products',
    '3X more acne-fighting active ingredients',
    'Proven to tackle acne in 80% of patients',
    '95% saw improvement in their acne in 12 weeks',
  ],
  ctaButton: {
    buttonText: 'Personalize your routine',
  },
};
