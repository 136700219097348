import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';
import React from 'react';
import { LOCAL_COLORS } from 'styles/constants';
import { ReactComponent as CheckSVG } from './images/orangeCheck.svg';

import {
  OuterContainer,
  InnerContainer,
  Header,
  TopContent,
  ValuePropListContainer,
  ValuePropContainer,
  ValueProp,
  SVGContainer,
  mobileCtaStyles,
  desktopCtaStyles,
} from './BenefitsBar.style';

export interface BenefitsBarProps {
  title: React.ReactNode;
  ctaButton: CtaButtonProps;
  valueProps: string[];
  backgroundColor?: string;
}

const moduleName = 'valuePropsListSection';

export const BenefitsBar = ({
  title,
  ctaButton,
  valueProps,
  backgroundColor = LOCAL_COLORS.surfaceBrandBolder,
}: BenefitsBarProps) => (
  <OuterContainer data-module={moduleName} backgroundColor={backgroundColor}>
    <InnerContainer>
      <TopContent>
        <Header>{title}</Header>
        {ctaButton && (
          <CtaButton
            {...ctaButton}
            css={desktopCtaStyles}
            color={
              backgroundColor === LOCAL_COLORS.surfaceBrandBolder
                ? 'dark-blue'
                : 'primary'
            }
          />
        )}
      </TopContent>
      <ValuePropListContainer>
        {valueProps &&
          valueProps.map((valueProp, index) => {
            return (
              <ValuePropContainer key={index}>
                <SVGContainer>
                  <CheckSVG />
                </SVGContainer>
                <ValueProp>
                  {valueProp}
                  <sup>{index + 1}</sup>
                </ValueProp>
              </ValuePropContainer>
            );
          })}
      </ValuePropListContainer>
      {ctaButton && (
        <CtaButton
          {...ctaButton}
          css={mobileCtaStyles}
          color={
            backgroundColor === LOCAL_COLORS.surfaceBrandBolder
              ? 'dark-blue'
              : 'primary'
          }
        />
      )}
    </InnerContainer>
  </OuterContainer>
);
