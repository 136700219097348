import { CTA_GET_STARTED } from 'consts/copy';
import { SeeTheDifferenceProps } from '../SeeTheDifference';
import { LOCAL_COLORS } from 'styles/constants';

export const SEE_THE_DIFFERENCE_RETAIL_OFFER_CONTENT: SeeTheDifferenceProps = {
  backgroundColor: LOCAL_COLORS.surfaceLight,
  body: "Skincare isn't one-size-fits-all, and we don't treat it that way. Your personalized formula specifically addresses your unique needs, so you can see better results.",
  competitorName: 'Over-the-counter',
  competitorValueProps: [
    'One-size-fits-all-formula',
    'Non-prescription ingredients only',
    'Research products and ingredients on your own',
    'Results based on trial and error',
  ],
  ctaButton: {
    buttonText: CTA_GET_STARTED,
    desktopSize: 'Small',
    disclaimerText:
      'Subject to medical consultation. Subscription required. Results may vary.',
    mobileSize: 'Small',
  },
  heading: 'See the personalized prescription difference',
  productName: 'Curology',
  productValueProps: [
    'Personalized formulas',
    'Prescription-strength ingredients like tretinoin',
    '1:1 support from a licensed dermatology provider',
    'Personalized treatment plan for more effective results',
  ],
};
